<template>
  <div>
    <div class="air__utils__heading">
      <h5>Proceso de Contabilización</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row mb-5">
      <div class="col">
        <a-tabs default-active-key="1" type="card" size="large">
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="calculator"/>
              Cálculo de Contabilización
            </span>
            <div class="card">
              <div class="card-body text-center">
                <!--BARRA DE BUSQUEDA  -->
                <searchingBar placeholder="Buscar por Clave o #SAP"
                              description
                              :components="['filter']"
                              @Fectch="GetData"
                              @filterValue="filterService = $event"
                              @pageSize="tableObject.pagination.pageSize = $event"
                              @currentPageValue="tableObject.currentPage = $event">
                  <div slot="searchDescription" class="text-left">
                    Ingrese Claves o Números de SAP separados por una coma (,).
                    <span class="font-italic">Ej  G_00000XXXXX1, SAP_00000XXXXX1</span>
                  </div>
                  <div class="row justify-content-start mb-4" slot="customFilters">
                    <div class="col-sm-12 col-md-6 col-lg-4 mt-3">
                      <globalPlantFilter @filterValue="filterWorkCenter = $event"/>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <!--SELECT -Contrato -->
                      <a-form-item label="Contrato">
                        <a-select v-model="filterContract" @change="GetData()" allowClear
                                  placeholder="Seleccionar Contrato" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in contractCombo" :key="option.id" :value="option.id">
                            {{ option.contract_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Contrato -->
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <!--SELECT -Cliente -->
                      <a-form-item label="Cliente">
                        <a-select v-model="filterClient" @change="GetData()" allowClear
                                  placeholder="Seleccionar Cliente" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in clientCombo" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Cliente -->
                    </div>
                  </div>
                </searchingBar>
                <!--BARRA DE BUSQUEDA  -->
                <accounting-manifest-table
                  :value="tableObject"
                  :buttonType="'accounting'"
                  @Filter="GetData"
                  @Calculate="CalculateAccounting"
                  class="mt-3"
                />
              </div>
            </div>
          </a-tab-pane>

          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="file-done"/>
              Residuos por Contabilizar
            </span>
            <div class="card">
              <div class="card-body text-center">
                <b-container fluid>
                  <!-- FILTROS-->
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6}">
                      <b-form-group id="from-group" label="Fecha inicial" label-for="from">
                        <b-form-datepicker v-model="fromDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="from" label="Fecha inicial" placeholder="Desde"
                        />
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group id="to-group" label="Fecha final" label-for="to">
                        <b-form-datepicker v-model="toDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="to" label="Fecha final" placeholder="Hasta"/>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="sap_programming_code"
                        label="Número de Programación"
                        label-for="sap_programming_code"
                      >
                        <b-form-select v-model="sapProgrammingCodeFilter" :options="sapProgrammingCodeCombo"
                                       id="sap_programming_code" label="Número de Programación"
                                       @change="GetDataWasteSpecial()"
                                       value-field="sap_programming_code" text-field="sap_programming_code">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Números de Programación</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="accounting-type"
                        label="Tipo de Contabilización"
                        label-for="accounting-type"
                      >
                        <b-form-select v-model="accountingTypeFilter" :options="accountingTypeCombo"
                                       id="accounting-type" label="Tipo de Contabilización"
                                       @change="GetDataWasteSpecial()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Tipos de Contabilización</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                  </a-row>
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <globalPlantFilter @filterValue="workCenterFilter = $event" class="mt-3"/>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Contrato -->
                      <a-form-item label="Contrato">
                        <a-select v-model="sapFilter" @change="GetDataWasteSpecial()" allowClear
                                  placeholder="Seleccionar Contrato" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in contractCombo" :key="option.id"
                                           :value="option.contract_code">
                            {{ option.contract_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Contrato -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Cliente -->
                      <a-form-item label="Cliente">
                        <a-select v-model="clientFilter" @change="GetDataWasteSpecial()" allowClear
                                  placeholder="Seleccionar Cliente" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in clientCombo" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Cliente -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 6 }">
                      <b-form-group id="status_sap_data" label="Estatus Sap" label-for="client" class="mt-3">
                        <b-form-select v-model="statusSapDataFilter" :options="statusSapDataCombo"
                                       id="status_sap_data" label="Estatus" @change="GetDataWasteSpecial()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Estatus</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24, offset:0 }" :sm="{ span: 24, offset:0 }" :md="{ span: 12, offset:4}"
                           :xl="{ span: 8, offset:8}" class="mt-sm-2 mt-md-4 mt-xl-2 mb-4">
                      <b-input-group-append>
                        <b-button variant="primary" block @click="GetDataWasteSpecial()" data-bs-toggle="tooltip"
                                  title="Buscar información">
                          Buscar
                          <b-icon icon="search"/>
                        </b-button>
                        <b-button variant="secondary" @click="refreshFilters()">
                          <b-icon icon="x"/>
                        </b-button>
                      </b-input-group-append>
                    </a-col>
                  </a-row>
                  <!-- FILTROS-->
                </b-container>
                <hr class="bg-primary">
                <!-- TABLE-->
                <div class="row mt-3 border-bottom border-w-2 border-primary">
                  <div class="col-md-12 col-lg-12 mb-4">
                    <waste-accounting-service-table :table-object="tableObjectSpecial"
                                                    @AddBatch="AddBatch"
                                                    @NotSendSap="NotSendSap"
                                                    @ShowATModal="ShowATModal"
                                                    @DeleteBatch="DeleteBatch"
                                                    @Filter="GetDataWasteSpecial"
                                                    @CancelAccounting="CancelAccounting"
                                                    @ShowSDMModal="OpenSapDataMaterialModal"
                                                    @setManualAccounting="openModalManualAccounting"
                                                    show-actions type-of-action="waste"/>
                  </div>
                </div>
                <!-- TABLE-->
              </div>
            </div>
          </a-tab-pane>

          <a-tab-pane key="3">
            <span slot="tab">
              <a-icon type="file-done"/>
              Respuesta de SAP
            </span>
            <div class="card">
              <div class="card-body text-center">
                <!--BARRA DE BUSQUEDA  -->
                <searchingBar placeholder="Buscar por Clave o #SAP"
                              description
                              :components="['filter']"
                              @Fectch="GetDataSapResponse"
                              @filterValue="filterService = $event"
                              @pageSize="tableObject.pagination.pageSize = $event"
                              @currentPageValue="tableObject.currentPage = $event">
                  <div slot="searchDescription" class="text-left">
                    Ingrese Claves o Números de SAP separados por una coma (,).
                    <span class="font-italic">Ej  G_00000XXXXX1, SAP_00000XXXXX1</span>
                  </div>
                  <div class="row justify-content-start mb-4" slot="customFilters">
                    <div class="col-sm-12 col-md-6 col-lg-4 mt-3">
                      <globalPlantFilter @filterValue="filterWorkCenter = $event"/>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <!--SELECT -Contrato -->
                      <a-form-item label="Contrato">
                        <a-select v-model="filterContract" @change="GetData()" allowClear
                                  placeholder="Seleccionar Contrato" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in contractCombo" :key="option.id" :value="option.id">
                            {{ option.contract_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Contrato -->
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <!--SELECT -Cliente -->
                      <a-form-item label="Cliente">
                        <a-select v-model="filterClient" @change="GetData()" allowClear
                                  placeholder="Seleccionar Cliente" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in clientCombo" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Cliente -->
                    </div>
                  </div>
                </searchingBar>
                <!--BARRA DE BUSQUEDA  -->
                <sap-responser-table
                  :value="tableObjectSapResponse"
                  :buttonType="'accounting'"
                  @Filter="GetDataSapResponse"
                  @Calculate="CalculateAccounting"
                  @OpenBillingModal="OpenBillingModal"
                  class="mt-3"
                />
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="4">
            <span slot="tab"><a-icon type="dollar"/>Residuos Contabilizar por Ventas
            </span>
            <div class="card">
              <div class="card-body text-center">
                <!--BARRA DE BUSQUEDA  -->
                <b-container fluid>
                  <!-- FILTROS-->
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6}">
                      <b-form-group id="from-group" label="Fecha inicial" label-for="from">
                        <b-form-datepicker v-model="fromDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="from" label="Fecha inicial" placeholder="Desde"
                        />
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group id="to-group" label="Fecha final" label-for="to">
                        <b-form-datepicker v-model="toDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="to" label="Fecha final" placeholder="Hasta"/>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="sap_programming_code"
                        label="Número de Programación"
                        label-for="sap_programming_code"
                      >
                        <b-form-select v-model="sapProgrammingCodeFilter" :options="sapProgrammingCodeCombo"
                                       id="sap_programming_code" label="Número de Programación"
                                       @change="GetSaleDataWaste()"
                                       value-field="sap_programming_code" text-field="sap_programming_code">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Números de Programación</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="accounting-type"
                        label="Tipo de Contabilización"
                        label-for="accounting-type"
                      >
                        <b-form-select v-model="accountingTypeFilter" :options="accountingTypeCombo"
                                       id="accounting-type" label="Tipo de Contabilización"
                                       @change="GetSaleDataWaste()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Tipos de Contabilización</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                  </a-row>
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <globalPlantFilter @filterValue="workCenterFilter = $event" class="mt-3"/>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Contrato -->
                      <a-form-item label="Contrato">
                        <a-select v-model="sapFilter" @change="GetSaleDataWaste()" allowClear
                                  placeholder="Seleccionar Contrato" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in contractCombo" :key="option.id"
                                           :value="option.contract_code">
                            {{ option.contract_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Contrato -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Cliente -->
                      <a-form-item label="Cliente">
                        <a-select v-model="clientFilter" @change="GetSaleDataWaste()" allowClear
                                  placeholder="Seleccionar Cliente" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in clientCombo" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Cliente -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 6 }">
                      <b-form-group id="status_sap_data" label="Estatus Sap" label-for="client" class="mt-3">
                        <b-form-select v-model="statusSapDataFilter" :options="statusSapDataCombo"
                                       id="status_sap_data" label="Estatus" @change="GetSaleDataWaste()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Estatus</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24, offset:0 }" :sm="{ span: 24, offset:0 }" :md="{ span: 12, offset:4}"
                           :xl="{ span: 8, offset:8}" class="mt-sm-2 mt-md-4 mt-xl-2 mb-4">
                      <b-input-group-append>
                        <b-button variant="primary" block @click="GetSaleDataWaste()" data-bs-toggle="tooltip"
                                  title="Buscar información">
                          Buscar
                          <b-icon icon="search"/>
                        </b-button>
                        <b-button variant="secondary" @click="refreshFilters()">
                          <b-icon icon="x"/>
                        </b-button>
                      </b-input-group-append>
                    </a-col>
                  </a-row>
                  <!-- FILTROS-->
                </b-container>
                <!--BARRA DE BUSQUEDA  -->
                <hr class="bg-primary">
                <!-- TABLE-->
                <div class="row mt-3 border-bottom border-w-2 border-primary">
                  <div class="col-md-12 col-lg-12 mb-4">
                    <waste-accounting-service-table :table-object="saleTableObject"
                                                    @AddBatch="AddBatch"
                                                    @NotSendSap="NotSendSap"
                                                    @ShowATModal="ShowATModal"
                                                    @DeleteBatch="DeleteBatch"
                                                    @Filter="GetSaleDataWaste"
                                                    @CancelAccounting="CancelAccounting"
                                                    @ShowSDMModal="OpenSapDataMaterialModal"
                                                    @setManualAccounting="openModalManualAccounting"
                                                    show-actions type-of-action="waste"/>
                  </div>
                </div>
                <!-- TABLE-->
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="5">
            <span slot="tab"><a-icon type="shopping-cart"/>Residuos Contabilizar por Compras</span>
            <div class="card">
              <div class="card-body text-center">
                <!--BARRA DE BUSQUEDA  -->
                <b-container fluid>
                  <!-- FILTROS-->
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6}">
                      <b-form-group id="from-group" label="Fecha inicial" label-for="from">
                        <b-form-datepicker v-model="fromDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="from" label="Fecha inicial" placeholder="Desde"
                        />
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group id="to-group" label="Fecha final" label-for="to">
                        <b-form-datepicker v-model="toDateFilter"
                                           reset-button label-reset-button="Limpiar"
                                           id="to" label="Fecha final" placeholder="Hasta"/>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="sap_programming_code"
                        label="Número de Programación"
                        label-for="sap_programming_code"
                      >
                        <b-form-select v-model="sapProgrammingCodeFilter" :options="sapProgrammingCodeCombo"
                                       id="sap_programming_code" label="Número de Programación"
                                       @change="GetPurchaseDataWaste()"
                                       value-field="sap_programming_code" text-field="sap_programming_code">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Números de Programación</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :xl="{ span: 6 }">
                      <b-form-group
                        id="accounting-type"
                        label="Tipo de Contabilización"
                        label-for="accounting-type"
                      >
                        <b-form-select v-model="accountingTypeFilter" :options="accountingTypeCombo"
                                       id="accounting-type" label="Tipo de Contabilización"
                                       @change="GetPurchaseDataWaste()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Tipos de Contabilización</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                  </a-row>
                  <a-row justify="space-around" :gutter="[16,16]">
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <globalPlantFilter @filterValue="workCenterFilter = $event" class="mt-3"/>
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Contrato -->
                      <a-form-item label="Contrato">
                        <a-select v-model="sapFilter" @change="GetPurchaseDataWaste()" allowClear
                                  placeholder="Seleccionar Contrato" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in contractCombo" :key="option.id"
                                           :value="option.contract_code">
                            {{ option.contract_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Contrato -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 8 }" :xl="{ span: 6 }">
                      <!--SELECT -Cliente -->
                      <a-form-item label="Cliente">
                        <a-select v-model="clientFilter" @change="GetPurchaseDataWaste()" allowClear
                                  placeholder="Seleccionar Cliente" style="width: 100%"
                                  show-search :filter-option="filterOption">
                          <a-select-option v-for="option in clientCombo" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <!--SELECT -Cliente -->
                    </a-col>
                    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 6 }">
                      <b-form-group id="status_sap_data" label="Estatus Sap" label-for="client" class="mt-3">
                        <b-form-select v-model="statusSapDataFilter" :options="statusSapDataCombo"
                                       id="status_sap_data" label="Estatus" @change="GetPurchaseDataWaste()"
                                       value-field="jsonApi.id" text-field="name">
                          <template slot="first">
                            <b-form-select-option value="">Todos los Estatus</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </a-col>
                    <a-col :xs="{ span: 24, offset:0 }" :sm="{ span: 24, offset:0 }" :md="{ span: 12, offset:4}"
                           :xl="{ span: 8, offset:8}" class="mt-sm-2 mt-md-4 mt-xl-2 mb-4">
                      <b-input-group-append>
                        <b-button variant="primary" block @click="GetPurchaseDataWaste()" data-bs-toggle="tooltip"
                                  title="Buscar información">
                          Buscar
                          <b-icon icon="search"/>
                        </b-button>
                        <b-button variant="secondary" @click="refreshFilters()">
                          <b-icon icon="x"/>
                        </b-button>
                      </b-input-group-append>
                    </a-col>
                  </a-row>
                  <!-- FILTROS-->
                </b-container>
                <!--BARRA DE BUSQUEDA  -->
                <hr class="bg-primary">
                <!-- TABLE-->
                <div class="row mt-3 border-bottom border-w-2 border-primary">
                  <div class="col-md-12 col-lg-12 mb-4">
                    <waste-accounting-service-table :table-object="purchaseTableObject"
                                                    isForPurchase
                                                    @AddBatch="AddBatch"
                                                    @NotSendSap="NotSendSap"
                                                    @ShowATModal="ShowATModal"
                                                    @DeleteBatch="DeleteBatch"
                                                    @Filter="GetPurchaseDataWaste"
                                                    @CancelAccounting="CancelAccounting"
                                                    @ShowSDMModal="OpenSapDataMaterialModal"
                                                    @setManualAccounting="openModalManualAccounting"
                                                    show-actions type-of-action="waste"/>
                  </div>
                </div>
                <!-- TABLE-->
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <close-billing-modal
      :show-modal="showBillingModal"
      :service-data="billingModalObject"
      @CloseBillingModal="CloseBillingModal"
      @FinishBillingProcess="FinishBillingProcess"
    />
    <b-modal size="xl"
             title="Modificación de Contabilización Especial"
             v-model="showAccountingTypeModal"
             @hide="CloseModal"
    >
      <b-container fluid>
        <a-form-model layout="horizontal"
                      :model="formAccountingTypeModal"
                      :rules="accountingTypeModalRules"
                      ref="AccountingTypeForm"
        >
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <a-form-model-item label="Contabilización Especial" class="mx-1" ref="accounting_type_id"
                                 prop="accounting_type_id">
                <a-select v-model="formAccountingTypeModal.accounting_type_id" placeholder="Seleccionar">
                  <a-select-option v-for="option in accountingTypes" :key="option.jsonApi.id"
                                   :value="option.jsonApi.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-show="!formAccountingTypeModal.load">
            <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 8, offset: 8 }">
              <responsive-button block variant="primary" pill size="md"
                                 text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="OnSubmitAT"
                                 class="mr-2"
              />
            </a-col>
          </a-row>
          <my-spinner :load="formAccountingTypeModal.load"></my-spinner>
        </a-form-model>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <b-modal size="xl"
             title="Edición del Material"
             v-model="showSapDataMaterialModal"
             @hide="CloseSapDataMaterialModal"
    >
      <b-container fluid>
        <a-form-model layout="horizontal"
                      :model="formSapMaterialsModal"
                      :rules="sapMaterialsModalRules"
                      ref="SapMaterialForm"
        >
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 6 }">
              <a-form-model-item label="Cantidad de Facturación:" class="mx-1" ref="billing_quantity"
                                 prop="billing_quantity">
                <a-input-number size="large" :min="0" :step="0.1" v-model="formSapMaterialsModal.billing_quantity"
                                style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 18 }">
              <a-form-model-item label="Unidad de Medida" class="mx-1" ref="um_billing" prop="um_billing">
                <a-select v-model="formSapMaterialsModal.um_billing" placeholder="Seleccionar">
                  <a-select-option v-for="option in umCombo" :key="option.jsonApi.id" :value="option.jsonApi.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <h5>Información de SAP</h5>
          <hr class="bg-primary">
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item label="POS" class="mx-1" ref="pos" prop="pos">
                <a-input-number size="large" :min="0" :step="0.1" v-model="formSapMaterialsModal.pos"
                                style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item label="TPOS" class="mx-1" ref="tpos" prop="tpos">
                <a-input size="large" v-model="formSapMaterialsModal.tpos" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item label="Precio Unitario" class="mx-1" ref="unit_price" prop="unit_price">
                <a-input-number size="large" :min="0" :step="0.1" v-model="formSapMaterialsModal.unit_price"
                                style="width: 100%"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-show="!formSapMaterialsModal.load">
            <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 8, offset: 8 }">
              <responsive-button block variant="primary"
                                 pill
                                 size="md"
                                 text="Guardar"
                                 responsive="md"
                                 icon="archive-fill"
                                 @ClickEvent="OnSubmitSapMaterials"
                                 class="mr-2"
              />
            </a-col>
          </a-row>
          <my-spinner :load="formAccountingTypeModal.load"></my-spinner>
        </a-form-model>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseSapDataMaterialModal"/>
      </template>
    </b-modal>

    <b-modal size="xl"
             title="Contabilización Manual"
             v-model="showModalManual"
             @hide="closeModalManualAccounting"
    >
      <b-container fluid>
        <a-form-model layout="horizontal"
                      :model="formAccountingManual"
                      :rules="accountingManualRules"
                      ref="ManualAccounting"
        >
          <h5>
            Información General
          </h5>
          <hr class="bg-primary">
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }" class="text-center">
              <span class="text-primary font-weight-bold">
                Servicio:
              </span>
              {{ formAccountingManual.service_planning }}
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }" class="text-center">
              <span class="text-primary font-weight-bold">
                Manifiesto:
              </span>
              {{ formAccountingManual.manifest }}
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }" class="text-center">
              <span class="text-primary font-weight-bold">
                Estatus Actual:
              </span>
              {{ formAccountingManual.status_sap_data }}
            </a-col>
          </a-row>

          <h5 class="pt-4">
            Información de SAP
          </h5>
          <hr class="bg-primary">
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item
                class="mx-1"
                ref="sap_programming_code"
                prop="sap_programming_code"
                label="No. de Programación:"
              >
                <a-input-number
                  :min="0"
                  :step="0"
                  size="large"
                  style="width: 100%"
                  v-model="formAccountingManual.sap_programming_code"
                  :disabled="formAccountingManual.enable_sap_programming_code"
                />
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item
                label="Contabilización del Pedido:"
                class="mx-1"
                ref="sap_accounting_code"
                prop="sap_accounting_code"
              >
                <a-input-number
                  :min="0"
                  :step="0"
                  size="large"
                  style="width: 100%"
                  v-model="formAccountingManual.sap_accounting_code"
                  :disabled="formAccountingManual.enable_sap_accounting_code"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item
                label="No. de Transporte:"
                class="mx-1"
                ref="sap_transport_code"
                prop="sap_transport_code"
              >
                <a-input-number
                  :min="0"
                  :step="0"
                  size="large"
                  style="width: 100%"
                  v-model="formAccountingManual.sap_transport_code"
                  :disabled="formAccountingManual.enable_sap_transport_code"
                />
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item
                label="Número de Facturación:"
                class="mx-1"
                ref="sap_billing_code"
                prop="sap_billing_code"
              >
                <a-input-number
                  :min="0"
                  :step="0"
                  size="large"
                  style="width: 100%"
                  v-model="formAccountingManual.sap_billing_code"
                  :disabled="formAccountingManual.enable_sap_billing_code"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-show="!formAccountingManual.load">
            <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 8, offset: 8 }">
              <responsive-button block
                                 variant="primary"
                                 pill
                                 size="md"
                                 class="mr-2"
                                 text="Guardar"
                                 responsive="md"
                                 icon="archive-fill"
                                 @ClickEvent="saveManualAccounting"
                                 v-show="formAccountingManual.status_sap_data !== 'Facturado'"
              />
            </a-col>
          </a-row>
          <my-spinner :load="formAccountingManual.load"></my-spinner>
        </a-form-model>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeModalManualAccounting"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Modal, notification } from 'ant-design-vue'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { AccountingMixin } from '@/mixins/core/AccountingMixin'
import wasteAccountingServiceTable
  from '@/views/geocycle/service_plannings/components/waste/wasteAccountingServiceTable'
import SapResponserTable from '@/views/geocycle/service_plannings/components/discharge/SapResponserTable'
import AccountingManifestTable from '@/views/geocycle/service_plannings/components/discharge/AccountingManifestTable'
import api from '@/services/axios'
import CloseBillingModal from '@/views/geocycle/service_plannings/accounting_service_plannings/CloseBillingModal'

export default {
  name: 'AccountingView',
  components: {
    CloseBillingModal,
    SapResponserTable,
    AccountingManifestTable,
    wasteAccountingServiceTable,
  },
  mixins: [apiRestMixin, getGeneralMixin, AccountingMixin],
  data() {
    return {
      // ** ROUTE VARIABLE
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Proceso de Contabilización',
          aIcon: '',
        },
      ],
      // ** ROUTE VARIABLE

      // ** RESOURCE TYPE TO CALCULATE DATA
      resourceTypeCalculate: 'specialSapData',
      // ** RESOURCE TYPE TO CALCULATE DATA

      // ** MODAL VARIABLE
      showModal: false,
      serviceFolio: '',
      // ** MODAL VARIABLE

      // ******************************************
      // ** BILLING MODAL VARIABLE
      showBillingModal: false,
      billingModalObject: {},
      // ** BILLING MODAL VARIABLE
      // ******************************************
      // Filtros de Tablas de Residuos Peligrosos
      sapFilter: undefined,
      clientFilter: undefined,
      sapProgrammingCodeFilter: '',
      workCenterFilter: '',
      fromDateFilter: '',
      toDateFilter: '',
      accountingTypeFilter: '',
      statusSapDataFilter: '',
      // Filtros de Tablas de Residuos Manejo Especial
      sapSpecialFilter: '',
      clientSpecialFilter: '',
      sapProgrammingCodeSpecialFilter: '',
      workCenterSpecialFilter: '',
      fromDateSpecialFilter: '',
      toDateSpecialFilter: '',
      accountingTypeSpecialFilter: '',
      statusSapDataSpecialFilter: '',
      // Combos para filtros
      sapCombo: [],
      clientCombo: [],
      sapProgrammingCodeCombo: [],
      workCenterCombo: [],
      accountingTypeCombo: [],
      statusSapDataCombo: [
        'Pendiente', 'En Batch', 'Programación del Pedido', 'Contabilización del Pedido', 'Creación del Transporte', 'Especificación del Transporte', 'Facturado', 'Sin Envío a SAP',
      ],
      // Table Data
      tableObjectSpecial: {
        data: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      // ************************************
      // ACCOUNTING TYPE MODAL
      accountingTypes: {},
      showAccountingTypeModal: false,
      formAccountingTypeModal: {
        sap_data_id: 0,
        accounting_type_id: undefined,
        client_accounting_type_code: '',
        client_accounting_type_name: '',
        load: false,
      },
      accountingTypeModalRules: {
        accounting_type_id: [
          {
            required: true,
            message: 'El campo de tipo de contabilización es obligatorio.',
            trigger: 'blur',
          },
        ],
      },
      // Sap Materials Form
      formSapMaterialsModal: {
        sap_data_material_id: undefined,
        billing_quantity: 0,
        um_billing: undefined,
        pos: 0,
        tpos: 0,
        unit_price: 0,
        load: false,
      },
      sapMaterialsModalRules: {
        sap_data_material_id: [
          {
            required: true,
            message: 'El campo  Material es obligatorio.',
            trigger: 'blur',
          },
        ],
        billing_quantity: [
          {
            required: true,
            message: 'El campo Cantidad es obligatorio.',
            trigger: 'blur',
          },
        ],
        um_billing: [
          {
            required: true,
            message: 'El campo Unida de Medida es obligatorio.',
            trigger: 'blur',
          },
        ],
      },
      umCombo: [],
      // ACCOUNTING TYPE MODAL
      // *******************************************
      // SAP DATA MATERIAL MODAL
      showSapDataMaterialModal: false,
      // SAP DATA MATERIAL MODAL
      // *******************************************
      // MANUAL ACCOUNTING
      showModalManual: false,
      formAccountingManual: {
        id: 0,
        service_planning: 0,
        status_sap_data: '',
        manifest: '',
        sap_programming_code: '',
        enable_sap_programming_code: false,
        sap_accounting_code: '',
        enable_sap_accounting_code: false,
        sap_transport_code: '',
        enable_sap_transport_code: false,
        sap_billing_code: '',
        enable_sap_billing_code: false,
        load: false,
      },
      accountingManualRules: {
        sap_programming_code: [
          {
            required: true,
            message: 'El campo Número de programación es obligatorio.',
            trigger: 'blur',
          },
        ],
      },
      // MANUAL ACCOUNTING
      // *******************************************
      // Tabs de Compra y Venta
      purchaseTableObject: {
        data: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      saleTableObject: {
        data: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      // Tabs de Compra y Venta
      // *******************************************
    }
  },
  methods: {
    // ******************************************
    // ******************************************
    // ** MODAL MANUAL ACCOUTING
    saveManualAccounting() {
      this.$refs.ManualAccounting.validate(valid => {
        if (valid) {
          this.formAccountingManual.load = true
          const me = this
          Modal.confirm({
            title: '¿Está seguro de realizar de almacenar la información manualmente?',
            okText: 'Si',
            okType: 'primary',
            cancelText: 'No',
            cancelType: 'secondary',
            zIndex: 3000,
            async onOk() {
              console.log('Creación del proceso de facturación')
              me.formAccountingManual.load = false
              api.put(`updateSapDataStatus/${me.formAccountingManual.id}`, me.formAccountingManual)
                .then(() => {
                  Modal.success({
                    title: 'Concluido',
                    content: 'Se ha contabilizado manualmente los datos de este servicio. Los materiales serán enviados a SAP.',
                    okText: 'Ok',
                    centered: true,
                  })
                  me.GetDataWasteSpecial()
                  me.closeModalManualAccounting()
                  me.tableObject.load = false
                }).catch(error => {
                  console.log(error)
                  me.tableObject.load = true
                })
            },
            onCancel() {
              me.closeModalManualAccounting()
            },
          })
        } else {
          return false
        }
      })
    },
    openModalManualAccounting(data) {
      this.formAccountingManual.id = data.jsonApi.id
      this.formAccountingManual.service_planning = data.service_planning
      this.formAccountingManual.status_sap_data = data.status_sap_data
      this.formAccountingManual.manifest = data.manifest

      if (data.sap_programming_code !== null) {
        this.formAccountingManual.sap_programming_code = data.sap_programming_code
        this.formAccountingManual.enable_sap_programming_code = true
      }

      if (data.sap_accounting_code !== null) {
        this.formAccountingManual.sap_accounting_code = data.sap_accounting_code
        this.formAccountingManual.enable_sap_accounting_code = true
      }

      if (data.sap_transport_code !== null) {
        this.formAccountingManual.sap_transport_code = data.sap_transport_code
        this.formAccountingManual.enable_sap_transport_code = true
      }

      if (data.sap_billing_code !== null) {
        this.formAccountingManual.sap_billing_code = data.sap_billing_code
        this.formAccountingManual.enable_sap_billing_code = true
      }

      this.showModalManual = true
    },
    closeModalManualAccounting() {
      this.showModalManual = false
      this.formAccountingManual = {
        id: 0,
        service_planning: 0,
        status_sap_data: '',
        manifest: '',
        sap_programming_code: '',
        enable_sap_programming_code: false,
        sap_accounting_code: '',
        enable_sap_accounting_code: false,
        sap_transport_code: '',
        enable_sap_transport_code: false,
        sap_billing_code: '',
        enable_sap_billing_code: false,
        load: false,
      }
    },
    // ******************************************
    // ******************************************
    // ** BILLING MODAL VARIABLE
    OpenBillingModal(paginationEvent = {}, data) {
      this.billingModalObject = {
        serviceCode: data.id,
        billingData: data.sapResponses,
      }
      this.showBillingModal = true
    },
    CloseBillingModal() {
      this.showBillingModal = false
    },
    FinishBillingProcess(initialData, billingFormSuccess) {
      this.UpdateBillingInformation(initialData, billingFormSuccess)
    },
    async UpdateBillingInformation(initialData, billingFormSuccess) {
      billingFormSuccess.is_billing = this.changeStatusToFlag(billingFormSuccess.billing_status_name)
      const resourceObj = {
        resourceType: 'sapResponses',
        form: billingFormSuccess,
      }
      await this.PutResource(resourceObj, this.SuccessBillingForm, this.CallOnError)
    },
    async SuccessBillingForm() {
      const me = this
      me.CloseBillingModal()
      await me.GetDataSapResponse()
      Modal.success({
        title: '¡Guardado!',
        content: 'La información de Facturación se ha almacenado exitosamente',
        zIndex: 3000,
      })
    },
    CallOnError() {
      notification.info({
        message: 'No se ha actualizado el registro, ya que ocurrió un error en el servidor',
      })
    },
    // ** BILLING MODAL VARIABLE
    // ******************************************
    // ******************************************
    async GetCombos() {
      this.clientCombo = await this.getJsonApiResources('clients', { sort: 'name' })
      this.sapProgrammingCodeCombo = await this.getJsonApiResources('servicePlannings', { sort: '-sap_programming_code' })
      this.workCenterCombo = await this.getJsonApiResources('workCenters', { sort: 'name' })
      this.accountingTypeCombo = await this.getJsonApiResources('accountingTypes', { sort: 'name' })
      this.sapCombo = await this.getJsonApiResources('clientContracts', { sort: 'contract_code' })
    },
    refreshFilters() {
      this.sapFilter = ''
      this.clientFilter = ''
      this.sapProgrammingCodeFilter = ''
      this.workCenterFilter = ''
      this.fromDateFilter = ''
      this.toDateFilter = ''
      this.accountingTypeFilter = ''
      this.statusSapDataFilter = ''
    },
    refreshSpecialFilters() {
      this.sapSpecialFilter = ''
      this.clientSpecialFilter = ''
      this.sapProgrammingCodeSpecialFilter = ''
      this.workCenterSpecialFilter = ''
      this.fromDateSpecialFilter = ''
      this.toDateSpecialFilter = ''
      this.accountingTypeSpecialFilter = ''
      this.statusSapDataSpecialFilter = ''
    },
    GetDataWasteSpecial(paginationEvent = {}) {
      const params = {
        include: 'sapDataMaterials,servicePlannings',
      }
      // Fechas
      if (this.fromDateFilter !== '' && this.toDateFilter !== '') params['filter[date]'] = `${this.fromDateFilter},${this.toDateFilter}`
      // No Programacion
      if (this.sapProgrammingCodeFilter) params['filter[sapProgrammingCode]'] = this.sapProgrammingCodeFilter
      // Tipo de Contabilización
      if (this.accountingTypeFilter) params['filter[clientAccountingTypeCode]'] = this.accountingTypeFilter
      // --------------------------------------------------------------
      // Planta
      if (this.workCenterFilter !== '') params['filter[plantCode]'] = this.workCenterFilter
      // Contrato SAP
      if (this.sapFilter) params['filter[sapContract]'] = this.sapFilter
      // Cliente
      if (this.clientFilter) params['filter[clientCode]'] = this.clientFilter
      // statusSapData
      if (this.statusSapDataFilter) params['filter[statusSapData]'] = this.statusSapDataFilter

      this.getJsonApiTable(paginationEvent, 'sapDatas', this.tableObjectSpecial, params)
    },
    // ************************************
    // ************************************contractCombo
    async GetData(paginationEvent = {}) {
      await this.GetMainTable(paginationEvent)
    },
    // *******************************************
    // *******************************************
    // ***** SAP RESPONSE DATA
    async GetDataSapResponse(paginationEvent = {}) {
      await this.MixGetSapResponseTable(paginationEvent)
    },
    ShowBillingModal() {
      console.log('Show modal')
    },
    // ***** SAP RESPONSE DATA
    // *******************************************
    // *******************************************
    async CalculateAccounting(data = {}) {
      const me = this
      me.tableObject.load = true

      const request = {
        service_planning_id: data.id,
      }

      Modal.confirm({
        title: '¿Está seguro de realizar el cálculo de la contabilización?',
        okText: 'Si',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        async onOk() {
          api.post('specialSapData', request)
            .then(response => {
              // me.load = false
              Modal.success({
                title: 'Concluido',
                content: 'Se ha concluido el cálculo para el proceso de contabilización correctamente. Los materiales serán enviados a SAP.',
                okText: 'Ok',
                centered: true,
              })
              me.GetData()
              me.tableObject.load = false
            }).catch(error => {
              console.log(error)
              me.tableObject.load = true
            })
        },
        onCancel() {
          me.CancelCalculate()
        },
      })
    },
    SuccessCallbackCalculate() {
      Modal.success({
        title: 'Concluido',
        content: 'Se ha concluido el cálculo para el proceso de contabilización correctamente. Los materiales serán enviados a SAP.',
        okText: 'Ok',
        centered: true,
      })
      this.GetData()
    },
    CancelCalculate() {
      Modal.info({
        title: 'Aviso',
        content: 'El cálculo de la contabilización se ha rechazado. No se han efectuado cambios en la base de datos.',
        okText: 'Ok',
        centered: true,
      })
    },
    // *******************************************
    // *******************************************
    // ACCOUNTING TYPE MODAL
    async GetAccountingTypeCatalogs() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'accountingTypes')
      this.accountingTypes = this.generalResponse
    },
    async GetUmCatalogs() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'ums')
      this.umCombo = this.generalResponse
    },
    ShowATModal(action, data = {}) {
      this.formAccountingTypeModal.sap_data_id = data.jsonApi.id
      this.formAccountingTypeModal.accounting_type_id = data.client_accounting_type_code
      this.showAccountingTypeModal = true
    },
    CloseModal() {
      this.showAccountingTypeModal = false
      this.$refs.AccountingTypeForm.resetFields()
    },
    OnSubmitATCallback() {
      this.GetDataWasteSpecial()
      this.CloseModal()
    },
    OnSubmitAT() {
      this.$refs.AccountingTypeForm.validate(valid => {
        if (valid) {
          const request = {
            sap_data_id: this.formAccountingTypeModal.sap_data_id,
            accounting_type_code: this.formAccountingTypeModal.accounting_type_id,
          }
          this.postAxiosResource('/updateSapData', request, this.OnSubmitATCallback)
        } else {
          return false
        }
      })
    },
    OnSubmitSapMaterials() {
      this.$refs.SapMaterialForm.validate(valid => {
        if (valid) {
          const request = {
            sap_data_material_id: this.formSapMaterialsModal.sap_data_material_id,
            billing_quantity: this.formSapMaterialsModal.billing_quantity,
            um_billing: this.formSapMaterialsModal.um_billing,
            pos: this.formSapMaterialsModal.pos,
            tpos: this.formSapMaterialsModal.tpos,
            unit_price: this.formSapMaterialsModal.unit_price,
          }
          this.postAxiosResource('/updateSapDataMaterial', request, this.OnSubmitSapMaterialsCallback)
        } else {
          return false
        }
      })
    },
    OnSubmitSapMaterialsCallback() {
      this.GetDataWasteSpecial()
      this.CloseSapDataMaterialModal()
    },
    // ACCOUNTING TYPE MODAL
    // *******************************************
    // *******************************************
    // CHANGE BILLING DATA
    OpenSapDataMaterialModal(action, data = {}) {
      this.formSapMaterialsModal.sap_data_material_id = data.jsonApi.id
      this.formSapMaterialsModal.billing_quantity = parseFloat(data.billing_quantity).toFixed(4)
      this.formSapMaterialsModal.um_billing = data.um_billing
      this.formSapMaterialsModal.pos = data.pos
      this.formSapMaterialsModal.tpos = data.tpos
      this.formSapMaterialsModal.unit_price = data.unit_price
      this.GetUmCatalogs()
      this.showSapDataMaterialModal = true
    },
    CloseSapDataMaterialModal() {
      this.showSapDataMaterialModal = false
      this.$refs.SapMaterialForm.resetFields()
    },
    // CHANGE BILLING DATA
    // *******************************************
    // *******************************************
    // DELETE MATERIAL FROM BATCH
    CancelAccounting(data = {}) {
      const me = this
      let isSapProcess = false
      Modal.confirm({
        title: '¿Está seguro de anular el cálculo de contabilización?',
        content: 'Este proceso le permitirá retroceder el servicio al proceso de descarga. Por favor verifique su elección',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.load = true
          isSapProcess = true
          const request = {
            sap_process: isSapProcess,
            sap_data_id: data.jsonApi.id,
          }
          api.post('/resetBatchData', request)
            .then(response => {
              Modal.success({
                title: '¡Cálculo Anulado!',
                content: 'El cálculo de contabilización ha sido anulado exitosamente. Usted puede regresar al proceso de descarga.',
                zIndex: 3000,
              })
              me.GetDataWasteSpecial()
              me.load = false
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: '¡No Anulado!',
            content: 'El cálculo de contabilización no se ha anulado y la Base de Datos no ha sido afectada.',
            zIndex: 3000,
          })
        },
      })
    },
    // DELETE MATERIAL FROM BATCH
    // *******************************************

    // *******************************************
    // DELETE MATERIAL FROM BATCH
    DeleteBatch(data = {}) {
      const me = this
      let isSapProcess = false
      Modal.confirm({
        title: '¿Está seguro de anular del Batch de SAP?',
        content: 'Usted podrá volver a generar el cálculo de contabilización...',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.load = true
          isSapProcess = true
          const request = {
            sap_process: isSapProcess,
            sap_data_id: data.jsonApi.id,
          }
          // Delete Object
          api.post('/cancelBatchData', request)
            .then(response => {
              Modal.success({
                title: '¡Anulado!',
                content: 'La generación del Batch de SAP se ha anulado exitosamente.',
                zIndex: 3000,
              })
              me.GetDataWasteSpecial()
              me.load = false
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: '¡No Anulado!',
            content: 'El Batch de SAP no se ha anulado y la Base de Datos no ha sido afectada.',
            zIndex: 3000,
          })
        },
      })
    },
    // DELETE MATERIAL FROM BATCH
    // *******************************************

    // *******************************************
    // ADD BATCH
    AddBatch(data = {}) {
      const me = this
      let isSapProcess = false
      Modal.confirm({
        title: '¿Está seguro de añadir al Batch de SAP?',
        content: 'Una vez enviado el residuo a Batch, permitirá que sea contabilizado por EcoNet...',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.load = true
          isSapProcess = true
          const request = {
            sap_process: isSapProcess,
            sap_data_id: data.jsonApi.id,
          }
          // Delete Object
          api.post('/addBatchData', request)
            .then(response => {
              Modal.success({
                title: '¡Generado!',
                content: 'La generación del Batch de SAP se ha generado exitosamente.',
                zIndex: 3000,
              })
              me.GetDataWasteSpecial()
              me.load = false
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: '¡No Generado!',
            content: 'El Batch de SAP no se ha generado y la Base de Datos no ha sido afectada.',
            zIndex: 3000,
          })
        },
      })
    },
    NotSendSap(data = {}) {
      const me = this
      let isSapProcess = false
      Modal.confirm({
        title: '¿Está seguro de no enviar al Batch para contabilizar en SAP?',
        content: 'Este proceso es irreversible, así que elija con cuidado...',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.load = true
          // **************************************************************************************
          // **************************************************************************************
          // Add New Modal Confirm
          Modal.confirm({
            title: '¿No enviará el manifiesto y sus residuos a procesamiento mediante SAP?',
            content: '!Está operación es irreversible, asegúrese de su elección¡',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            cancelType: 'secondary',
            zIndex: 3000,
            onOk() {
              isSapProcess = true
              const request = {
                sap_process: isSapProcess,
                sap_data_id: data.jsonApi.id,
              }
              // Delete Object
              api.post('/deleteDataToBatch', request)
                .then(response => {
                  Modal.success({
                    title: '¡Generado!',
                    content: 'La generación del Batch de SAP se ha generado exitosamente.',
                    zIndex: 3000,
                  })
                  me.GetDataWasteSpecial()
                  me.load = false
                }).catch(error => {
                  console.log(error)
                  me.load = false
                })
            },
            onCancel() {
              isSapProcess = false
              const request = {
                sap_process: isSapProcess,
                sap_data_id: data.jsonApi.id,
              }
              // Delete Object
              api.post('/deleteDataToBatch', request)
                .then(response => {
                  Modal.success({
                    title: '¡Generado!',
                    content: 'La generación del Batch de SAP se ha generado exitosamente.',
                    zIndex: 3000,
                  })
                  me.GetDataWasteSpecial()
                  me.load = false
                }).catch(error => {
                  console.log(error)
                  me.load = false
                })
            },
          })
          // Add New Modal Confirm
          // **************************************************************************************
          // **************************************************************************************
        },
        onCancel() {
          Modal.info({
            title: '¡No Generado!',
            content: 'El Batch de SAP no se ha generado y la Base de Datos no ha sido afectada.',
            zIndex: 3000,
          })
        },
      })
    },
    // ADD BATCH
    // *******************************************
    // *******************************************
    // Fetching Combos
    async FetchCombos() {
      this.contractCombo = await this.GetResource('/clientContracts', {
        sort: 'contract_code',
      })
      this.clientCombo = await this.GetResource('/clients', {
        sort: 'name',
      })
    },
    // Fetching Combos
    // *******************************************
    // *******************************************
    // Tabs de Compra y Venta
    GetPurchaseDataWaste(paginationEvent = {}) {
      const params = {
        include: 'sapDataMaterials,servicePlannings',
        // 'filter[servicePlanningServiceType]': 'ZM2O', //Venta
        'filter[servicePlanningServiceType]': 'ZM2T', // compra
      }
      // Fechas
      if (this.fromDateFilter !== '' && this.toDateFilter !== '') params['filter[date]'] = `${this.fromDateFilter},${this.toDateFilter}`
      // No Programacion
      if (this.sapProgrammingCodeFilter) params['filter[sapProgrammingCode]'] = this.sapProgrammingCodeFilter
      // Tipo de Contabilización
      if (this.accountingTypeFilter) params['filter[clientAccountingTypeCode]'] = this.accountingTypeFilter
      // --------------------------------------------------------------
      // Planta
      if (this.workCenterFilter !== '') params['filter[plantCode]'] = this.workCenterFilter
      // Contrato SAP
      if (this.sapFilter) params['filter[sapContract]'] = this.sapFilter
      // Cliente
      if (this.clientFilter) params['filter[clientCode]'] = this.clientFilter
      // statusSapData
      if (this.statusSapDataFilter) params['filter[statusSapData]'] = this.statusSapDataFilter

      this.getJsonApiTable(paginationEvent, 'sapDatas', this.purchaseTableObject, params)
    },
    GetSaleDataWaste(paginationEvent = {}) {
      const params = {
        include: 'sapDataMaterials,servicePlannings',
        'filter[servicePlanningServiceType]': 'ZM2O', // Venta
        // 'filter[servicePlanningServiceType]': 'ZM2T', // compra
      }
      // Fechas
      if (this.fromDateFilter !== '' && this.toDateFilter !== '') params['filter[date]'] = `${this.fromDateFilter},${this.toDateFilter}`
      // No Programacion
      if (this.sapProgrammingCodeFilter) params['filter[sapProgrammingCode]'] = this.sapProgrammingCodeFilter
      // Tipo de Contabilización
      if (this.accountingTypeFilter) params['filter[clientAccountingTypeCode]'] = this.accountingTypeFilter
      // --------------------------------------------------------------
      // Planta
      if (this.workCenterFilter !== '') params['filter[plantCode]'] = this.workCenterFilter
      // Contrato SAP
      if (this.sapFilter) params['filter[sapContract]'] = this.sapFilter
      // Cliente
      if (this.clientFilter) params['filter[clientCode]'] = this.clientFilter
      // statusSapData
      if (this.statusSapDataFilter) params['filter[statusSapData]'] = this.statusSapDataFilter

      this.getJsonApiTable(paginationEvent, 'sapDatas', this.saleTableObject, params)
    },
    // Tabs de Compra y Venta
    // *******************************************
  },
  async mounted() {
    await this.GetData()
    await this.FetchCombos()
    await this.GetDataWasteSpecial()
    await this.GetAccountingTypeCatalogs()
    await this.GetCombos()
  },
}
</script>

<style scoped>
.border-w-2 {
  border-width: 2px !important;
}
</style>
